// import Vue from 'vue'
import baseContextComponent from "@/components/baseContextComponent";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { DistributoreAssociatoAnagraficaEdit } from "@/models/commerciali/DistributoreAssociatoAnagraficaEdit";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import { CommercialeCommissioneSimula } from "@/models/commerciali/commercialeCommissioneSimula";
import { CommissioniSimulaResult } from "../../../models/commerciali/commissioniSimulaResult";
import { filterBy } from "@progress/kendo-data-query";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.COMMERCIALI;

@Component({})
export default class commissioniPage extends Vue {
	get PERMESSI(): string[] { return [permessi.BK.Commerciali.COMMISSIONI]; }
	commerciali: TextValueItem[] = [];
	commercialiFilter: any = null;
	get commercialiSource() {
		var self = this;
		return self.dataSourceFilter(self.commerciali, self.commercialiFilter);
	}
	onCommercialiFilterChange(event: any) {
		this.commercialiFilter = event.filter;
	}
	commercialeSelected: TextValueItem = null;

	distributori: TextValueItem[] = [];
	distributoriFilter: any = null;
	get distributoriSource() {
		var self = this;
		return self.dataSourceFilter(self.distributori, self.distributoriFilter);
	}
	onDistributoriFilterChange(event: any) {
		this.distributoriFilter = event.filter;
	}
	distributoreSelected: TextValueItem = null;

	prodotti: TextValueItem[] = [];
	prodottiFilter: any = null;
	get prodottiSource() {
		var self = this;
		return self.dataSourceFilter(self.prodotti, self.prodottiFilter);
	}
	onProdottiFilterChange(event: any) {
		this.prodottiFilter = event.filter;
	}
	prodottoSelected: TextValueItem = null;

	dataSourceFilter(source: TextValueItem[], filter: any): TextValueItem[] {
		const data = source.slice();
		if (filter == null)
			return data;
		// debugger;
		var filtered = filterBy(data, filter);
		return filtered;
	}

	result: CommissioniSimulaResult = null;

	mounted() {
		this.getCommercialiDropDown();
	}

	@Watch("commercialeSelected")
	onCommercialeSelectedChange(newValue: TextValueItem) {
		//if (newValue && newValue.value) {

		let val = (newValue || {}).value as number;
		this.distributoreSelected = null;
		this.getDistributoriDropDown(val);
	}

	@Watch("distributoreSelected")
	onDistributoreSelectedChange(newValue: TextValueItem) {
		//if (newValue && newValue.value) {
		let val = (newValue || {}).value as number;
		this.prodottoSelected = null;
		this.getProdottiDropDown(val);
	}

	getCommercialiDropDown() {
		var self = this;
		return api
			.getDatiDropDown(api.endpoint.DROPDOWN.COMMERCIALI)
			.then(res => {
				self.commerciali = res;
			})
			.catch(err => {
				console.log(err);
			});
	}

	getDistributoriDropDown(value: number) {
		var self = this;
		if (value == null)
			self.distributori = [];
		else {
			return api
				.getDatiDropDown(api.endpoint.COMMISSIONI.DROPDOWN.DISTRIBUTORI.replace("{id}", value.toString()))
				.then(res => {
					self.distributori = res;
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	getProdottiDropDown(value: number) {
		var self = this;
		if (value == null)
			self.prodotti = [];
		else {
			return api
				.getDatiDropDown(api.endpoint.COMMISSIONI.DROPDOWN.PRODOTTI_DISTRIBUTORE.replace("{commId}", self.commercialeSelected.value.toString()).replace("{disId}", value.toString()))
				.then(res => {
					self.prodotti = res;
				})
				.catch(err => {
					console.log(err);
				});

		}
	}

	onExecuteAction() {
		if (!this.commercialeSelected || !this.commercialeSelected.value) {

			this.$bvToast.toast('Selezionare un commerciale per effettuare il calcolo', {
				variant: "warning",
				title: 'Attenzione',
				solid: true
			});
		}
		else {
			var dati: CommercialeCommissioneSimula = {
				commercialeID: this.commercialeSelected ? (this.commercialeSelected.value as number) : 0,
				distributoreID: this.distributoreSelected ? (this.distributoreSelected.value as number) : 0,
				pacchettoID: this.prodottoSelected ? (this.prodottoSelected.value as number) : 0
			};

			api.simulaCommercialeCommissioni(dati)
				.then(res => {
					if (res && res.length) this.result = res[0];
				})
				.catch(err => {
					console.log(err);
				});
		}
	}

	get calcoloResult() {
		if (this.result && this.result.calcolo) {
			return this.result.calcolo;
		}

		return "";
	}
}
